import React from "react";
import moment from 'moment';
import MaterialTable from "@material-table/core";
import Header from "./Header";


// https://github.com/effiongcharles/material-ui-table-crud-restapi/blob/master/src/App.js


export const Settings = () => {
    const HOST = 'https://jolly-alert-mollusk.ngrok-free.app';
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        const url = HOST + '/getSettings';
        const data = { "bot_name": localStorage.getItem("bot_name") }
        fetch(url, { // optional fetch options
            body: JSON.stringify(data), // you may send any data, encoded as you wish. shall match content-type 
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            redirect: 'follow', // *manual, follow, error
            referrer: 'no-referrer', // *client, no-referrer
        })
            .then(response => response.json())
            .then(result => {
                // for (var _item of result.result) {
                //     _item.last_signal_add_time_converted = moment(_item.last_signal_add_time * 1000).format("MMM DD, HH:mm:ss");
                //     _item.last_signals_process_time_converted = moment(_item.last_signals_process_time * 1000).format("MMM DD, HH:mm:ss");
                //     _item.last_orders_process_time_converted = moment(_item.last_orders_process_time * 1000).format("MMM DD, HH:mm:ss");
                // }
                setData(result.result);
                setLoadingState(false);
            });
    };

    var columns = [
        { title: "Bot balance limit USDT", field: "bot_balance_limit", align: "left", editable: "always", searchable: false, filtering: false },
        { title: "Stop enter deals", field: "stop_entering_deals", align: "left", editable: "always", searchable: false, filtering: false }, 
        { title: "TP %", field: "tp_size", align: "left", editable: "always", searchable: false, filtering: false },
        { title: "SL %", field: "sl_size", align: "left", editable: "always", searchable: false, filtering: false },
        { title: "Leverage", field: "leverage", align: "left", editable: "always", searchable: false, filtering: false },
        // { title: "TS callback rate", field: "trailing_stop_callback_rate", align: "left", editable: "always", searchable: false, filtering: false },
        // { title: "TS activation %", field: "trailing_stop_activation_price_percentage", align: "left", editable: "always", searchable: false, filtering: false },
        { title: "Allowed deals", field: "allowed_deal_types", align: "left", editable: "always", editPlaceholder: "Comma separated deal types", searchable: false, filtering: false, render: rowData => {
            return <div className="tra">
                {rowData.allowed_deal_types.map((e, key) => {
                    var customClass = `badge bg-secondary`
                    if (e === "LONG") {
                        customClass = `badge bg-secondary green`
                    } else if (e === "SHORT") {
                        customClass = `badge bg-secondary red`
                    }
                    // var customClass = `badge bg-secondary ${color}`
                    return <span className={customClass} style={{margin: "2px", fontSize: "12px"}} key={key}>{e}</span>;
                    // return <span className={customClass} key={key}>{e}</span>;
                })}
            </div>
        } },
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Settings"
                    options={{
                        // addRowPosition: "first",
                        search: false,
                        columnsButton: false,
                        filtering: false,
                        pageSize: 20,
                        rowStyle: (rowData) => {
                            return {
                                backgroundColor: '#fff',
                                fontSize: "14px"
                            }
                        },
                        headerStyle: {
                            fontWeight: "bold"
                        },
                        filterCellStyle: {
                            paddingTop: "0px",
                            paddingBottom: "8px",
                        }
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                if (newData.bot_balance_limit !== oldData.bot_balance_limit ||
                                    newData.tp_size !== oldData.tp_size ||
                                    newData.sl_size !== oldData.sl_size ||
                                    newData.leverage !== oldData.leverage ||
                                    newData.stop_entering_deals !== oldData.stop_entering_deals ||
                                    newData.allowed_deal_types !== oldData.allowed_deal_types
                                    // newData.trailing_stop_callback_rate !== oldData.trailing_stop_callback_rate ||
                                    // newData.trailing_stop_activation_price_percentage !== oldData.trailing_stop_activation_price_percentage
                                ) {
                                    const url = HOST + '/setSettings';
                                    let allowedDealTypesArray = [];
                                    if (typeof(newData.allowed_deal_types) === "string") {
                                        allowedDealTypesArray = newData.allowed_deal_types.split(",");
                                    } else {
                                        allowedDealTypesArray = newData.allowed_deal_types;
                                    }
                                    const _data = {
                                        "bot_name": localStorage.getItem("bot_name"), 
                                        "bot_balance_limit": newData.bot_balance_limit, 
                                        "tp_size": newData.tp_size,
                                        "sl_size": newData.sl_size,
                                        "leverage": newData.leverage,
                                        "stop_entering_deals": newData.stop_entering_deals,
                                        "allowed_deal_types": allowedDealTypesArray
                                        // "trailing_stop_callback_rate": newData.trailing_stop_callback_rate,
                                        // "trailing_stop_activation_price_percentage": newData.trailing_stop_activation_price_percentage
                                    }
                                    fetch(url, { // optional fetch options
                                        body: JSON.stringify(_data), // you may send any data, encoded as you wish. shall match content-type 
                                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                        credentials: 'same-origin', // include, same-origin, *omit
                                        headers: {
                                            'content-type': 'application/json'
                                        },
                                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                        mode: 'cors', // no-cors, cors, *same-origin
                                        redirect: 'follow', // *manual, follow, error
                                        referrer: 'no-referrer', // *client, no-referrer
                                    })
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.result === "success") {
                                                const dataUpdate = [...data];
                                                const index = oldData.tableData.index;
                                                newData.allowed_deal_types = allowedDealTypesArray;
                                                dataUpdate[index] = newData;
                                                setData([...dataUpdate]);

                                                resolve();
                                            }
                                        });
                                } else {
                                    reject();
                                }
                            })
                    }}
                />
        }
    </div>;
} 