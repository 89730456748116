import React, { useState, useEffect } from "react";


function Header() {
  const currentUrl = window.location.pathname;

  const [role, setRole] = useState();

  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, [])

  const switchBot = async e => {
    localStorage.removeItem("bot_name");
    window.location.href = "/select";
  };

  return (

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid navContainer">
        <a className="navbar-brand" href="/"><img className="header-logo img-fluid" src='/assets/logos/logo_black.png' alt=''></img></a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a id='statusLink' className={currentUrl == '/status' ? "nav-link active" : "nav-link"} href="status" aria-current={currentUrl == '/status' ? "page" : ""}>Status</a>
            </li>
            <li className="nav-item">
              <a id='dealsLink' className={currentUrl == '/deals' ? "nav-link active" : "nav-link"} href="deals" aria-current={currentUrl == '/deals' ? "page" : ""}>Deals</a>
            </li>
            <li className="nav-item">
              <a id='signalsLink' className={currentUrl == '/signals' ? "nav-link active" : "nav-link"} href="signals" aria-current={currentUrl == '/signals' ? "page" : ""}>Signals</a>
            </li>
            <li className="nav-item">
              <a id='ordersLink' className={currentUrl == '/orders' ? "nav-link active" : "nav-link"} href="orders" aria-current={currentUrl == '/orders' ? "page" : ""}>Orders</a>
            </li>
            <li className="nav-item">
              <a id='botsLink' className={currentUrl == '/bots' ? "nav-link active" : "nav-link"} href="bots" aria-current={currentUrl == '/bots' ? "page" : ""}>Bots</a>
            </li>
            <li className="nav-item">
              <a id='moneyPoolLink' className={currentUrl == '/moneyPool' ? "nav-link active" : "nav-link"} href="moneyPool" aria-current={currentUrl == '/moneyPool' ? "page" : ""}>Money Pool</a>
            </li>
            <li className="nav-item">
              <a id='settingsLink' className={currentUrl == '/settings' ? "nav-link active" : "nav-link"} href="settings" aria-current={currentUrl == '/settings' ? "page" : ""}>Settings</a>
            </li>
            <li className="nav-item">
              <a id='coreSettingsLink' className={currentUrl == '/coreSettings' ? "nav-link active" : "nav-link"} href="coreSettings" aria-current={currentUrl == '/coreSettings' ? "page" : ""}>Core Settings</a>
            </li>
          </ul>
          <button className="nav-item" style={{ border: "none", backgroundColor: "transparent", padding: "0px" }} onClick={switchBot}>
            Switch Bot
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
