import { Navigate, Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Orders } from "./components/Orders";
import { Deals } from "./components/Deals";
import { SelectBotPage } from "./components/SelectBotPage";
import { Signals } from "./components/Signals";
import { Status } from "./components/Status";
import { Settings } from "./components/Settings";
import { CoreSettings } from "./components/CoreSettings";
import { Bots } from "./components/Bots";
import { MoneyPool } from "./components/MoneyPool";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/status" element={<Status />} />
        <Route path="/deals" element={<Deals />} />
        <Route path="/signals" element={<Signals />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/bots" element={<Bots />} />
        <Route path="/moneyPool" element={<MoneyPool />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/coreSettings" element={<CoreSettings />} />
        <Route path="/select" element={<SelectBotPage />} />
        <Route path="/" element={localStorage.getItem('bot_name') ? <Navigate replace to="/status" /> : <Navigate replace to="/select" />} />
      </Routes>
    </Router>
  );
}

export default App;